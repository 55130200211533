import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  Select,
  MenuItem
} from '@mui/material';
// components
import Page from 'components/page';
import Scrollbar from 'components/scrollBar';
import DatatTableAdmin from 'components/dataTableAdmin';
import ModalConfirmation from 'components/modalConfirmation';
import UserMoreMenu from 'components/userMoreMenu';
// services
import { getPatterns, createPattern, updatePattern, deletePattern } from "services/pattern.service";
import { getProviders } from "services/provider.service";

import ModalCreate from "./ModalCreate";

export default function Pattern() {
  const history = useHistory();

  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [patterns, setPatterns] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const { isSuccess: isSuccessProvider, data: resProvider } = useQuery(["providers"], getProviders);
  const { isSuccess, data } = useQuery(
    ["patterns", { providerId: selectedProvider }], 
    getPatterns,
    { enabled: selectedProvider !== "" }
  );

  useEffect(() => {
    if (isSuccess) {
      setPatterns(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessProvider) {
      setProviders(resProvider);

      if (resProvider?.length > 0) {
        setSelectedProvider(resProvider[0]._id);
      }
    }
  }, [isSuccessProvider, resProvider]);

  const columnPatterns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "Text ",
        selector: row => row.text,
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => {
              setDataEdit({
                _id: row._id,
                text: row.text,
                providerId: row.providerId
              });
              setShowCreate(true);
            }}
            onDelete={() => {
              setDeletedId(row._id);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history, patterns]);

  const sourcePatterns = useMemo(() => {
    return patterns;

  }, [patterns]);

  const submitPattern = useMutation(values => {
    if (values._id) {
      return updatePattern({
        values: values,
        patternId: values._id
      });
    } else {
      return createPattern({values});
    }
  });

  const onSubmitPattern = (values) => {
    submitPattern.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          if (values._id) {
            setPatterns(() => {
              const clonePatterns = [...patterns];
              const selectedPattern = clonePatterns.find(x => x._id === values._id);
              if (selectedPattern) {
                selectedPattern.text = values.text;
              }

              return clonePatterns;
            });
            setDataEdit(null);
          } else {
            if (res.providerId === selectedProvider) {
              setPatterns([...patterns, res])
            }
          }

          setShowCreate(false);
        }
      }
    });
  }
 
  const handleDelete = useMutation(deleteId => {
    return deletePattern({deleteId});
  });

  const onDelete = () => {
    handleDelete.mutate(deleteId, {
      onSuccess: () => {
        setPatterns(patterns.filter(x => x._id !== deleteId));
        setShow(false);
      }
    });
  }

  return (
    <Page title="Pattern">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Pattern
          </Typography>
          <Select
            value={selectedProvider}
            onChange={e => setSelectedProvider(e.target.value)}
            style={{width: "200px"}}
          >
            {
              providers.map((provider, index) => (
                <MenuItem key={index} value={provider._id}>{provider.name}</MenuItem>
              ))
            }
          </Select>
          <div>
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => setShowCreate(true)}
            >
              New Pattern
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <DatatTableAdmin
                columns={columnPatterns}
                data={sourcePatterns}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => onDelete()}
        />

        <ModalCreate 
          open={showCreate}
          dataPattern={dataEdit}
          handleClose={() => {
            setShowCreate(false);
            setDataEdit(null);
          }}
          handleOk={(values) => onSubmitPattern(values)}
        />
      </Container>
    </Page>
  );
}
