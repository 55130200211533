import React, { useEffect, useRef, useState } from "react";

import { replaceLastThreeWithAsterisks } from "utils/common";
import LoadingProgress from "./LoadingProgress";
import { getActivity } from "./data";

const listActivity = getActivity();

export default function RecentActivity() {
  const activityRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activityRef.current) {
        activityRef.current.scrollTop += 53;
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <div className="subtitle">
        Recent Activity

        <span></span>
      </div>
      {
        progress < 100 ? (
          <div className="p-20">
            <LoadingProgress 
              progress={progress}
              setProgress={setProgress}
              buffer={buffer}
              setBuffer={setBuffer}
            />
          </div>
        ) : (
          <div className="list-box" ref={activityRef}>
            {
              listActivity.map((name, index) => (
                <div key={index} style={{marginTop: index === 0 ? "0px" : "10px"}}>
                  <span>{replaceLastThreeWithAsterisks(name)}</span> Has inject Cheat SLOT
                </div>
              ))
            }
          </div>
        )
      }
    </React.Fragment>
  )
}