import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

import Page from 'components/page';
import { getProvider, updateProvider } from "services/provider.service";
import { getBackendPath } from "utils/common";

import ProviderForm from "./ProviderForm";

const EditProviderForm = () => {
  const history = useHistory();
  const queryClient = new QueryClient();

  const { id } = useParams();
  const { isSuccess: isSuccessProvider, data: resProvider } = useQuery(["provider-edit", { providerId: id }], getProvider);

  const [providerState, setProviderState] = useState({});

  useEffect(() => {
    if (isSuccessProvider) {
      setProviderState(resProvider);
    }
  }, [isSuccessProvider, resProvider]);


  const submitProvider = useMutation(values => {
    return updateProvider({values, providerId: id});
  });

  const onSubmitProvider = (values) => {
    submitProvider.mutate(values, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["provider-edit", { providerId: id }], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["provider-edit", { providerId: id }]);
        history.goBack();
      }
    });
  }

  return (
    <Page title="Edit Provider">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Provider
          </Typography>
        </Stack>

        <Card>
          {
            (isSuccessProvider === true && !isEmpty(providerState)) && (
              <ProviderForm 
                initialValues={{
                  ...providerState,
                  image: (providerState.image && providerState.image !== "") ? getBackendPath(providerState.image) : ""
                }}
                onSubmit={onSubmitProvider}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditProviderForm;