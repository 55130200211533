import React from "react";
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ModalContinue = ({
  open,
  handleClose,
  handleOk,
  props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {"CONGRATULATIONS!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          CHEAT ROBOTMAXWIN SUDAH AKTIF, SILAHKAN LOGIN KE DALAM AKUN ANDA, PASTIKAN SALDO CUKUP UNTUK MENYELESAIKAN POLA YANG DIBERIKAN UNTUK MAXWIN !!!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} autoFocus variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalContinue.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
};

ModalContinue.defaultProps = {
  open: false,
  handleClose: () => {},
  handleOk: () => {},
};

export default ModalContinue;
