import React from "react";
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const ModalCountDown = ({
  open,
  handleClose,
  handleOk,
  props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          WAktu Aktif Cheat RobotMaxWin Telah Habis, Silahkan Inject Kembali !!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} autoFocus variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCountDown.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
};

ModalCountDown.defaultProps = {
  open: false,
  handleClose: () => {},
  handleOk: () => {},
};

export default ModalCountDown;
