import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

import Page from 'components/page';
import { getGame, updateGame } from "services/game.service";
import { getProviders } from "services/provider.service";
import { getBackendPath } from "utils/common";

import GameForm from "./GameForm";

const EditGameForm = () => {
  const history = useHistory();
  const queryClient = new QueryClient();

  const { id } = useParams();
  const { isSuccess: isSuccessGame, data: resGame } = useQuery(["game-edit", { gameId: id }], getGame);
  const { isSuccess: isSuccessProvider, data: resProvider } = useQuery(["providers"], getProviders);

  const [gameState, setGameState] = useState({});
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    if (isSuccessProvider) {
      setProviders(resProvider);
    }
  }, [isSuccessProvider, resProvider]);

  useEffect(() => {
    if (isSuccessGame) {
      setGameState(resGame);
    }
  }, [isSuccessGame, resGame]);


  const submitGame = useMutation(values => {
    return updateGame({values, gameId: id});
  });

  const onSubmitGame = (values) => {
    submitGame.mutate(values, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["game-edit", { gameId: id }], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["game-edit", { gameId: id }]);
        history.goBack();
      }
    });
  }

  return (
    <Page title="Edit Game">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Game
          </Typography>
        </Stack>

        <Card>
          {
            (isSuccessGame === true && !isEmpty(gameState)) && (
              <GameForm 
                initialValues={{
                  ...gameState,
                  image: (gameState.image && gameState.image !== "") ? getBackendPath(gameState.image) : ""
                }}
                providers={providers}
                onSubmit={onSubmitGame}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditGameForm;