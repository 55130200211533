import { useState, useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FF9800' : '#FF9800',
  },
}));

export default function LoadingProgress({
  progress,
  setProgress,
  buffer,
  setBuffer
}) {
  const progressRef = useRef(() => {});

  useEffect(() => {
    progressRef.current = () => {
      if (progress < 100) {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);


  return (
    <BorderLinearProgress variant="buffer" value={progress} valueBuffer={buffer} ref={progressRef} />
  )
}