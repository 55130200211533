import React, { useState, useEffect } from "react";
import Typing from 'react-typing-animation';

import ModalContinue from "./ModalContinue";
import { FakeCodeWrapper } from "./styled";

const fakeTexts = [
  {
    text: "> Connecting to the global server...",
    statusText: "Connected"
  },
  {
    text: "> Performing User authentication...",
    statusText: "Done"
  },
  {
    text: "> Encrypting server: 256bit_Packet_Encryption...",
    statusText: "Done"
  },
  {
    text: "> Retrieving current server script: read_source_server_source...",
    statusText: "Done"
  },
  {
    text: "> Connect to database...",
    statusText: "Done"
  },
  {
    text: "> Attempt (1) to bypass the Security System...",
    statusText: "Done"
  },
  {
    text: "> Attempt (2) to bypass the Security System...",
    statusText: "Done"
  },
  {
    text: "> Cracking Root Shell System...",
    statusText: "Done"
  },
  {
    text: "> Patching Running System...",
    statusText: "Done"
  },
  {
    text: "> Changing packets in the database...",
    statusText: "Done"
  },
  {
    text: "> Connecting to All Slots Server...",
    statusText: "Done"
  },
  {
    text: "> Connecting to All Slots Database...",
    statusText: "Done"
  },
  {
    text: "> Patching Running System...",
    statusText: "Done"
  },
  {
    text: "> Logout From System...",
    statusText: "Done"
  },
  {
    text: "> SUCCESS!!",
    statusText: "Success"
  }
]

export default function FakeCode({
  setFinishedInject,
  setIsStartInject
}) {
  const [elements, setElements] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const generateText = (fake) => {
    return (
      fake.statusText !== "Success" ? (
        <Typing speed={-1}>
          <span className="normal-text">{fake.text}</span>
          <span className="done-text">{fake.statusText}</span>
        </Typing>
      ) : (
        <Typing speed={-1}>
          <span className="success-text">{fake.text}</span>
        </Typing>
      )
    )
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (elements.length <= fakeTexts.length) {
        const newElement = generateText(fakeTexts[elements.length]);
        setElements(prevElements => [...prevElements, newElement]);
      }
    }, 800);

    if (elements.length === fakeTexts.length) {
      clearTimeout(timeout);
      setOpenModal(true);
    }

    return () => clearTimeout(timeout);
  }, [elements]);

  const closeModal = () => {
    setFinishedInject(true);
    setIsStartInject(false);
    setOpenModal(false);
  }

  return (
    <React.Fragment>
      <FakeCodeWrapper>
        <div className="fake-header">
          {">_Processing"}
        </div>
        <div className="fake-content">
          {
            elements.map((el, index) => (
              <React.Fragment key={index}>
                {el}
              </React.Fragment>
            ))
          }
        </div>
      </FakeCodeWrapper>

      <ModalContinue 
        open={openModal}
        handleOk={() => closeModal()}
        handleClose={() => closeModal()}
      />
    </React.Fragment>
  )
}