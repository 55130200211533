import React, { useState } from "react";
import Countdown from 'react-countdown';
import { FormControlLabel, Switch, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-2-outline';
import circleOutline from '@iconify/icons-eva/close-circle-outline';

import { getBackendPath, getRandomValuesFromArray } from "utils/common";
import { ResultWrapper, MobileResultWrapper, CountDownWrapper } from "./styled";

import ModalCountDown from "./ModalCountDown";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export default function ResultCheat({
  setting,
  values
}) {
  const windowWidth = window.innerWidth;
  const [openFinish, setOpenFinish] = useState(false);
  const randomMinutes = [20, 21, 22, 23, 24, 25];

  const selectedMinute = getRandomValuesFromArray(randomMinutes, 1);

  const handleRedirect = () => {
    if (setting.loginLink && setting.loginLink !== "") {
      window.open(setting.loginLink, '_blank');
    }
  }

  const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setOpenFinish(true);
      return (
        <div>
          <span>{hours === 0 ? "00" : hours}</span>
          <span className="ml-2 mr-2">:</span>
          <span>{minutes === 0 ? "00" : minutes}</span>
          <span className="ml-2 mr-2">:</span>
          <span>{seconds === 0 ? "00" : seconds}</span>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div>
          <span>{hours === 0 ? "00" : hours}</span>
          <span className="ml-2 mr-2">:</span>
          <span>{minutes === 0 ? "00" : minutes}</span>
          <span className="ml-2 mr-2">:</span>
          <span>{seconds === 0 ? "00" : seconds}</span>
        </div>
      );
    }
  };
  

  return (
    <div>
      <p style={{textAlign: "center", fontSize: "20px"}}>Sisa Waktu Aktif RobotMaxwin</p>
      <CountDownWrapper>
        <Countdown
          date={Date.now() + ((selectedMinute?.length > 0 ? selectedMinute[0] : 25) * 60 * 1000)}
          renderer={countDownRenderer}
        />
      </CountDownWrapper>
      
      {
        values.map((value, index) => (
          <React.Fragment key={index}>
            {
              index !== 0 && (
                <hr style={{marginTop: "15px"}} />
              )
            }
    
            {
              windowWidth < 550 ? (
                <MobileResultWrapper className={(index !== 0) ? "mt-2" : ""}>
                  <div key={index}>
                    <img src={getBackendPath(value.image)} alt="" />
                    <div className="title-game">{value.name}</div>
                    <p className="rtp-font">RTP : <span>{value.rtp}%</span></p>
                  </div>
                  <div>
                    <div className="pola-game">
                      <div>
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} checked={true} />}
                          label="Spin Gratis 1X"
                        />
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} checked={true} />}
                          label="Double Chance"
                        />
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} checked={true} />}
                          label="Anti Ban"
                        />
                      </div>
                      <p className="pattern-title">POLA MAX WIN</p>
                      <p className={`pattern-value ${value.enableCheckClose ? "" : "ml-1"}`}>Rekomendasi Deposit: Rp. {value.totalAmount ? value.totalAmount : 0}</p>
                      <p className={`pattern-value ${value.enableCheckClose ? "" : "ml-1 mb-1"}`}>Nominal Taruhan: Rp. {value.randomAmount ? value.randomAmount : 0}</p>
                      {
                        value.patterns.map((pattern, gameIdx) => (
                          <p key={gameIdx}>
                            {
                              value.enableCheckClose === true && (
                                pattern.randomBooleans.map((boolean, boolIdx) => {
                                  if (boolean === true) {
                                    return (
                                      <Icon 
                                        key={boolIdx}
                                        icon={checkmarkCircleFill} 
                                        className="green-color"
                                        width={30} 
                                        height={30} 
                                      />
                                    )
                                  }
      
                                  return (
                                    <Icon
                                      key={boolIdx} 
                                      icon={circleOutline} 
                                      className="red-color"
                                      width={30} 
                                      height={30} 
                                    />
                                  )
                                })
                              )
                            }
                            <span className="ml-1">
                              {pattern.text}
                            </span>
                          </p>
                        ))
                      }
                    </div>
                    <Button
                      className="full-btn"
                      variant="contained"
                      onClick={() => handleRedirect()}
                    >
                      LOGIN
                    </Button>
                  </div>
                </MobileResultWrapper>
              ) : (
                <ResultWrapper className={(index !== 0) ? "mt-2" : ""}>
                  <div key={index}>
                    <img src={getBackendPath(value.image)} alt="" />
                    <p className="rtp-font">RTP : <span>{value.rtp}%</span></p>
                    <Button
                      className="full-btn"
                      variant="contained"
                      onClick={() => handleRedirect()}
                    >
                      LOGIN
                    </Button>
                  </div>
                  <div>
                    <div className="title-game">{value.name}</div>
                    <div>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} checked={true} />}
                        label="Spin Gratis 1X"
                      />
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} checked={true} />}
                        label="Double Chance"
                      />
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} checked={true} />}
                        label="Anti Ban"
                      />
                    </div>
                    <div className="pola-game">
                      <p className="pattern-title">POLA MAX WIN</p>
                      <p className={`pattern-value ${value.enableCheckClose ? "" : "ml-1"}`}>Rekomendasi Deposit: Rp. {value.totalAmount ? value.totalAmount : 0}</p>
                      <p className={`pattern-value ${value.enableCheckClose ? "" : "ml-1 mb-1"}`}>Nominal Taruhan: Rp. {value.randomAmount ? value.randomAmount : 0}</p>
                      {
                        value.patterns.map((pattern, gameIdx) => (
                          <p key={gameIdx}>
                            {
                              value.enableCheckClose === true && (
                                pattern.randomBooleans.map((boolean, boolIdx) => {
                                  if (boolean === true) {
                                    return (
                                      <Icon 
                                        key={boolIdx}
                                        icon={checkmarkCircleFill} 
                                        className="green-color"
                                        width={30} 
                                        height={30} 
                                      />
                                    )
                                  }
      
                                  return (
                                    <Icon
                                      key={boolIdx} 
                                      icon={circleOutline} 
                                      className="red-color"
                                      width={30} 
                                      height={30} 
                                    />
                                  )
                                })
                              )
                            }
                            <span className="ml-1">
                              {pattern.text}
                            </span>
                          </p>
                        ))
                      }
                    </div>
                  </div>
                </ResultWrapper>
              )
            }
          </React.Fragment>
        ))
      }

      <ModalCountDown 
        open={openFinish}
        handleOk={() => {
          setOpenFinish(false);
          window.location.reload();
        }}
        handleClose={() => {
          setOpenFinish(false);
          window.location.reload();
        }}
      />
    </div>
  )
}