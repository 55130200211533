import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import styled from "styled-components";

const StyledContent = styled.div`
  width: 250px;
  padding-top: 20px;
`;

const ModalCreate = ({
  open,
  dataUser,
  handleClose,
  handleOk,
  props
}) => {
  const type = dataUser?._id ? "Update" : "Create";
  const [userName, setUserName] = useState("");

  useEffect(() => {
    setUserName(dataUser?.userName ?? "");
  }, [dataUser]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {type} User
      </DialogTitle>
      <DialogContent>
        <StyledContent>
          <TextField
            fullWidth
            label="Username"
            value={userName}
            onChange={e => setUserName(e.target.value)}
          />
        </StyledContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button 
          autoFocus
          onClick={() => handleOk({
            userName: userName,
            _id: dataUser?._id
          })} 
        >
          {type}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCreate.propTypes = {
  open: PropTypes.bool,
  dataUser: PropTypes.object,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
};

ModalCreate.defaultProps = {
  open: false,
  dataUser: {
    userName: ""
  },
  handleClose: () => {},
  handleOk: () => {},
};

export default ModalCreate;
