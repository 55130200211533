import styled from "styled-components";

export const UploadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  cursor: pointer;
  background-size: cover;
  background-image: ${props => props.image ? `url(${props.image})` : "none"};

  svg {
    color: #637381;
  }

  img {
    position: absolute;
    width: 150px;
    height: 150px;
    object-fit: cover;
    z-index: -1;
  }
`;