import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getProviders = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/provider`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getProvider = async ({ queryKey }) => {
  const { providerId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/provider/${providerId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const createProvider = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/provider/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updateProvider = async ({values, providerId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/provider/${providerId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const deleteProvider = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/provider/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}