import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import styled from "styled-components";

import { getProviders } from "services/provider.service";

const StyledContent = styled.div`
  width: 350px;
  padding-top: 20px;
`;

const ModalCreate = ({
  open,
  dataPattern,
  handleClose,
  handleOk,
  props
}) => {
  const type = dataPattern?._id ? "Update" : "Create";

  const { isSuccess, data } = useQuery(["providers"], getProviders);

  const [text, setText] = useState("");
  const [providerId, setProviderId] = useState("");
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.length > 0 && type === "Create") {
        setProviderId(data[0]._id);
      }

      setProviders(data);
    }
  }, [isSuccess, data, type]);

  useEffect(() => {
    if (dataPattern !== null) {
      setText(dataPattern?.text);
      setProviderId(dataPattern?.providerId);
    }
  }, [dataPattern]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {type} Pattern
      </DialogTitle>
      <DialogContent>
        <StyledContent>
          <Select
            fullWidth
            className="mb-2"
            label="Select Provider"
            value={providerId}
            onChange={e => setProviderId(e.target.value)}
          >
            {
              providers.map((provider, index) => (
                <MenuItem key={index} value={provider._id}>{provider.name}</MenuItem>
              ))
            }
          </Select>

          <TextField
            fullWidth
            multiline={true}
            rows={3}
            label="Text"
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </StyledContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button 
          autoFocus
          onClick={() => handleOk({
            text: text,
            providerId: providerId,
            _id: dataPattern?._id
          })} 
        >
          {type}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCreate.propTypes = {
  open: PropTypes.bool,
  dataPattern: PropTypes.object,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
};

ModalCreate.defaultProps = {
  open: false,
  dataPattern: {
    text: ""
  },
  handleClose: () => {},
  handleOk: () => {},
};

export default ModalCreate;
