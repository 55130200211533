import React, { useState } from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Icon } from "@iconify/react";
 import plusFill from "@iconify/icons-eva/plus-fill";

import { UploadBox } from "styles";

const GameForm = ({
  initialValues,
  providers,
  onSubmit
}) => {
  const GameSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    providerId: Yup.string().required('Provider is required'),
  });
  
  const [previewImg, setPreviewImg] = useState(initialValues.image);

  const handleSubmitFormik = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("providerId", values.providerId);

    if (values.fileImage) {
      formData.append("fileImage", values.fileImage);
    }

    onSubmit(formData);
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
		validationSchema: GameSchema,
    onSubmit: (values) => handleSubmitFormik(values)
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const onUpload = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setPreviewImg(reader.result);
      }

      setFieldValue("fileImage", file);
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Select
              fullWidth
              label="Select Provider"
              {...getFieldProps('providerId')}
              error={Boolean(touched.providerId && errors.providerId)}
              helperText={touched.providerId && errors.providerId}
            >
              {
                providers.map((provider, index) => (
                  <MenuItem key={index} value={provider._id}>{provider.name}</MenuItem>
                ))
              }
            </Select>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <label htmlFor="gameImage" className="border-image">
              <input
                id="gameImage"
                name="gameImage"
                style={{ display: 'none' }}
                type="file"
                onChange={e => onUpload(e)}
              />
              {
                previewImg ? (
                  <UploadBox component="span">
                    <img src={previewImg} alt="gameImage" />
                    <Icon icon={plusFill} width={25} height={25} />
                  </UploadBox>
                ) : (
                  <UploadBox component="span">
                    <Icon icon={plusFill} width={25} height={25} />
                  </UploadBox>
                )
              }
            </label>
          </Stack>
        </Stack>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

GameForm.propTypes = {
  initialValues: PropTypes.object,
  providers: PropTypes.array,
  onSubmit: PropTypes.func,
};

GameForm.defaultProps = {
  initialValues: {
    name: "",
  },
  providers: [],
  onSubmit: () => {}
};

export default GameForm;
