import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

import Page from 'components/page';
import { createProvider } from "services/provider.service";

import ProviderForm from "./ProviderForm";

const CreateProviderForm = () => {
  const history = useHistory();

  const submitProvider = useMutation(values => {
    return createProvider({values});
  });

  const onSubmitProvider = (values) => {
    submitProvider.mutate(values, {
      onSuccess: (res) => {
        if (res)
          history.goBack();
      }
    });
  }

  return (
    <Page title="Create Provider">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Provider
          </Typography>
        </Stack>

        <Card>
          <ProviderForm 
            onSubmit={onSubmitProvider}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CreateProviderForm;