import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Stack } from "@mui/material";

import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { UploadBox } from "styles";

const BgImageSetting = ({
  bgImageBase64,
  setFieldValue
}) => {
  const [previewImg, setPreviewImg] = useState(bgImageBase64);

  const onUpload = (e, field) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        if (field === "fileBgImage") {
          setPreviewImg(reader.result);
        }
      }

      setFieldValue(field, file);
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Homepage Background Image
      </Typography>
      <Stack spacing={3} sx={{m: 2}}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <label htmlFor="bg-home-image" className="border-image">
            <input
              id="bg-home-image"
              name="bg-home-image"
              style={{ display: 'none' }}
              type="file"
              onChange={e => onUpload(e, "fileBgImage")}
            />
            {
              previewImg ? (
                <UploadBox component="span">
                  <img src={previewImg} alt="bgimage" />
                  <Icon icon={plusFill} width={25} height={25} />
                </UploadBox>
              ) : (
                <UploadBox component="span">
                  <Icon icon={plusFill} width={25} height={25} />
                </UploadBox>
              )
            }
          </label>
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

BgImageSetting.propTypes = {
  setFieldValue: PropTypes.func,
};

BgImageSetting.defaultProps = {
  setFieldValue: () => {}
};

export default BgImageSetting;
