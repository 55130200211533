import * as XLSX from "xlsx";

export const getLocalStorageUser = () => {
  let user = "";
  let currentUser = localStorage.getItem("currentUser");
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    user = currentUser;
  }

  return user;
}

export const exportXlsx = (values, sheetName, downloadName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(values);
  XLSX.utils.book_append_sheet(wb, ws, sheetName ? sheetName : "Sheet1");
  XLSX.writeFile(wb, `${downloadName ? downloadName : "download"}.xlsx`)
}

export const generateString = (prefix = "", length = 10) => {
  let result = "";
  let numbers = "0123456789";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let numbersLength = numbers.length;
  let charactersLength = characters.length;

  for (let i = 0; i < length; i++ ) {
    if (i % 2 === 0) {
      result += numbers.charAt(Math.floor(Math.random() * numbersLength));
    } else {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  }

  return `${prefix}${result}`;
}

export const removeSpace = (value) => {
  if (!value) return "";
  
  return value.replace(/\s/g, '');
}

export const convertRgb = (value) => {
  if (!value) return "#fff";
  
  return `rgb(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
}

export const convertGraident = (gradients) => {
  let mergeColor = "";
  gradients?.map((gradient, index) => {
    if (gradients.length - 1 === index) {
      mergeColor += `${gradient.color}`;
    } else {
      mergeColor += `${gradient.color}, `;
    }
  })

  return `-webkit-linear-gradient(${mergeColor})`;
}

export const getBackendPath = (image) => {
  return `${process.env.REACT_APP_API_URL}${image.replace("assets\\", "/").replace("assets", "")}`;
}

export const replaceLastThreeWithAsterisks = (str) => {
  if (str.length < 3) {
    return str; // Return the string as is if it's less than 3 characters long
  }

  const asterisks = '*'.repeat(3); // Create a string of three asterisks
  const replacedStr = str.substring(0, str.length - 3) + asterisks;

  return replacedStr;
}

export const getRandomValuesFromArray = (arr, count) => {
  const shuffled = arr.sort(() => 0.5 - Math.random()); // Shuffle the array
  return shuffled.slice(0, count); // Return the first 'count' elements
}

export const getRandomNumber = (minValue, maxValue) => {
  const min = minValue;
  const max = maxValue;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getRandomValueFromPercentageArray = (arr) => {
  const totalPercentage = arr.reduce((total, item) => total + item.percentage, 0);
  const randomValue = Math.random() * totalPercentage;
  let patternArr = [];

  let selectedPercentage = 0;
  let accumulatedPercentage = 0;
  for (let i = 0; i < arr.length; i++) {
    accumulatedPercentage += arr[i].percentage;
    if (randomValue < accumulatedPercentage) {
      selectedPercentage = arr[i].percentage;
      break;
    }
  }

  const listArr = arr.filter(x => x.percentage >= selectedPercentage);
  if (listArr.length > 0) {
    patternArr = Array(4).fill("").map((_) => {
      const randomIndex = Math.floor(Math.random() * listArr.length);
      return listArr[randomIndex].value;
    });
  }

  return {
    listArr: listArr,
    patternArr: patternArr
  }
}

export const getRandomValueByPercentage = (data) => {
  const cumulativeRanges = data.reduce((acc, item) => {
    acc.push({
      bet: item.bet,
      scaledProbability: acc.length === 0 ? (item.percentage ? item.percentage : 0) : acc[acc.length - 1].scaledProbability + (item.percentage ? item.percentage : 0),
    });
    return acc;
  }, []);

  const totalPercentageChange = cumulativeRanges[cumulativeRanges.length - 1].scaledProbability;

  // Generate a random number within the range of the total percentage change
  const randomChange = Math.random() * (totalPercentageChange);
  // Find the bet based on the random number using scaled probabilities
  const selectedBet = cumulativeRanges.find((range) => randomChange <= range.scaledProbability);

  return selectedBet ? selectedBet.bet : data[data.length - 1].bet;
}

export const generateRandomBooleans = (count) => {
  const randomBooleans = Array(count)
    .fill(false)
    .map(() => Math.random() < 0.5);

  const hasTrueValue = randomBooleans.includes(true);

  if (!hasTrueValue) {
    const randomIndex = Math.floor(Math.random() * count);
    randomBooleans[randomIndex] = true;
  }

  if (randomBooleans[0] === true) {
    randomBooleans[1] = false;
  }

  if (randomBooleans[1] === true) {
    randomBooleans[0] = false;
  }

  return randomBooleans;
}
