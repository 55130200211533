import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  Select,
  MenuItem
} from '@mui/material';
// components
import Page from 'components/page';
import Scrollbar from 'components/scrollBar';
import DatatTableAdmin from 'components/dataTableAdmin';
import ModalConfirmation from 'components/modalConfirmation';
import UserMoreMenu from 'components/userMoreMenu';
// services
import { getBetAmounts, createBetAmount, updateBetAmount, deleteBetAmount } from "services/betAmount.service";
import { getProviders } from "services/provider.service";

import ModalCreate from "./ModalCreate";

export default function BetAmount() {
  const history = useHistory();

  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [betAmounts, setBetAmounts] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const { isSuccess: isSuccessProvider, data: resProvider } = useQuery(["providers"], getProviders);
  const { isSuccess, data } = useQuery(
    ["betAmounts", { providerId: selectedProvider }], 
    getBetAmounts,
    { enabled: selectedProvider !== "" }
  );


  useEffect(() => {
    if (isSuccess) {
      setBetAmounts(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessProvider) {
      setProviders(resProvider);

      if (resProvider?.length > 0) {
        setSelectedProvider(resProvider[0]._id);
      }
    }
  }, [isSuccessProvider, resProvider]);

  const columnBetAmounts = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "Bet Amount ",
        selector: row => row.bet,
        sortable: true,
      },
      {
        name: "Percentage ",
        selector: row => `${row.percentage ? row.percentage : 0}%`,
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => {
              setDataEdit({
                _id: row._id,
                bet: row.bet,
                percentage: row.percentage,
                providerId: row.providerId
              });
              setShowCreate(true);
            }}
            onDelete={() => {
              setDeletedId(row._id);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history, betAmounts]);

  const sourceBetAmounts = useMemo(() => {
    return betAmounts;

  }, [betAmounts]);

  const submitBetAmount = useMutation(values => {
    if (values._id) {
      return updateBetAmount({
        values: values,
        betAmountId: values._id,
      });
    } else {
      return createBetAmount({values});
    }
  });

  const onSubmitBetAmount = (values) => {
    submitBetAmount.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          if (values._id) {
            setBetAmounts(() => {
              const cloneBetAmounts = [...betAmounts];
              const selectedBetAmount = cloneBetAmounts.find(x => x._id === values._id);
              if (selectedBetAmount) {
                selectedBetAmount.bet = values.bet;
                selectedBetAmount.percentage = values.percentage;
              }

              return cloneBetAmounts;
            });
            setDataEdit(null);
          } else {
            if (res.providerId === selectedProvider) {
              setBetAmounts([...betAmounts, res])
            }
          }

          setShowCreate(false);
        }
      }
    });
  }
 
  const handleDelete = useMutation(deleteId => {
    return deleteBetAmount({deleteId});
  });

  const onDelete = () => {
    handleDelete.mutate(deleteId, {
      onSuccess: () => {
        setBetAmounts(betAmounts.filter(x => x._id !== deleteId));
        setShow(false);
      }
    });
  }
  
  return (
    <Page title="BetAmount">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            BetAmount
          </Typography>
          <Select
            value={selectedProvider}
            onChange={e => setSelectedProvider(e.target.value)}
            style={{width: "200px"}}
          >
            {
              providers.map((provider, index) => (
                <MenuItem key={index} value={provider._id}>{provider.name}</MenuItem>
              ))
            }
          </Select>
          <div>
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => setShowCreate(true)}
            >
              New BetAmount
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <DatatTableAdmin
                columns={columnBetAmounts}
                data={sourceBetAmounts}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => onDelete()}
        />

        <ModalCreate 
          open={showCreate}
          dataBetAmount={dataEdit}
          handleClose={() => {
            setShowCreate(false);
            setDataEdit(null);
          }}
          handleOk={(values) => onSubmitBetAmount(values)}
        />
      </Container>
    </Page>
  );
}
