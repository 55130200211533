import React from "react";
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ModalDaftar = ({
  open,
  handleClose,
  handleOk,
  props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {"WARNING!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Silahkan Daftar Terlebih Dahulu, Jika sudah daftar dan masih tidak dapat mengaktifkan cheat Silahkan hubungi WhatsApp atau Live Chat Customer Service
        </DialogContentText>
        <Button
          className="mt-2"
          style={{width: "100%", backgroundColor: "#FF9800"}}
          variant="contained"
          onClick={() => handleOk()}
        >
          DAFTAR
        </Button>
      </DialogContent>
    </Dialog>
  );
}

ModalDaftar.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
};

ModalDaftar.defaultProps = {
  open: false,
  handleClose: () => {},
  handleOk: () => {},
};

export default ModalDaftar;
