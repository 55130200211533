import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getSetting = async () => {
  try {
    const res = await axiosReq.get(`/api/setting`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getProviders = async () => {
  try {
    const res = await axiosReq.get(`/api/providers`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getGames = async ({ queryKey }) => {
  try {
    const { providerId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/games`, {
      params: {
        providerId: providerId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getPatterns = async ({ queryKey }) => {
  try {
    const { providerId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/patterns`, {
      params: {
        providerId: providerId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getBetAmounts = async ({ queryKey }) => {
  try {
    const { providerId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/bets`, {
      params: {
        providerId: providerId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getUsers = async (userName) => {
  try {
    const res = await axiosReq.get(`/api/users`, {
      params: {
        userName: userName
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const addUser = async (data) => {
  try {
    const res = await axiosReq.post(`/api/users`, data);
    alertSuccess("Username anda terdaftar");
    return res.data;
  } catch(err) {
    alertSuccess(err.response?.data?.message);
  }
}