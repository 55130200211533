import React, { useState } from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Switch, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Icon } from "@iconify/react";
 import plusFill from "@iconify/icons-eva/plus-fill";

import { UploadBox } from "styles";

const ProviderForm = ({
  initialValues,
  onSubmit
}) => {
  const ProviderSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    minValue: Yup.number().required('Min Value is required'),
    maxValue: Yup.number().required('Max Value is required'),
  });
  
  const [previewImg, setPreviewImg] = useState(initialValues.image);

  const handleSubmitFormik = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("minValue", values.minValue);
    formData.append("maxValue", values.maxValue);
    formData.append("enableCheckClose", values.enableCheckClose);

    if (values.fileImage) {
      formData.append("fileImage", values.fileImage);
    }

    onSubmit(formData);
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
		validationSchema: ProviderSchema,
    onSubmit: (values) => handleSubmitFormik(values)
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const onUpload = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setPreviewImg(reader.result);
      }

      setFieldValue("fileImage", file);
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Min Value"
              {...getFieldProps('minValue')}
              error={Boolean(touched.minValue && errors.minValue)}
              helperText={touched.minValue && errors.minValue}
            />
            <TextField
              fullWidth
              label="Max Value"
              {...getFieldProps('maxValue')}
              error={Boolean(touched.maxValue && errors.maxValue)}
              helperText={touched.maxValue && errors.maxValue}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <label htmlFor="providerImage" className="border-image">
              <input
                id="providerImage"
                name="providerImage"
                style={{ display: 'none' }}
                type="file"
                onChange={e => onUpload(e)}
              />
              {
                previewImg ? (
                  <UploadBox component="span">
                    <img src={previewImg} alt="providerImage" />
                    <Icon icon={plusFill} width={25} height={25} />
                  </UploadBox>
                ) : (
                  <UploadBox component="span">
                    <Icon icon={plusFill} width={25} height={25} />
                  </UploadBox>
                )
              }
            </label>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControlLabel 
              onChange={() => setFieldValue("enableCheckClose", !values.enableCheckClose)} 
              control={<Switch checked={values.enableCheckClose} />} 
              label="Enable Check Close" 
            />
          </Stack>
        </Stack>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

ProviderForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

ProviderForm.defaultProps = {
  initialValues: {
    name: "",
    minValue: 0,
    maxValue: 0,
    enableCheckClose: false
  },
  onSubmit: () => {}
};

export default ProviderForm;
