import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Login from 'pages/admin/login';
// admin user
import AdminUser from "pages/admin/adminUser";
import CreateAdminUser from "pages/admin/adminUser/Create";
import EditAdminUser from "pages/admin/adminUser/Edit";
// user
import User from "pages/admin/user";
// pattern
import Pattern from "pages/admin/pattern";
// pattern
import BetAmount from "pages/admin/betAmount";
// provider
import Provider from "pages/admin/provider";
import CreateProvider from "pages/admin/provider/Create";
import EditProvider from "pages/admin/provider/Edit";
// game
import Game from "pages/admin/game";
import CreateGame from "pages/admin/game/Create";
import EditGame from "pages/admin/game/Edit";
// Seting
import Setting from "pages/admin/setting";
import ChangePassword from "pages/admin/changepassword";

// user side
import Home from 'pages/user/home';
// import UserTurnover from 'pages/user/turnover';

import ProtectedRoute from "./protected.route";
import UserRoute from "./user.route";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/admin/login" component={Login} />

        {/* admin */}
        <ProtectedRoute exact path="/admin" component={AdminUser} />
        <ProtectedRoute exact path="/admin/adminUser" role="admin" component={AdminUser} />
        <ProtectedRoute exact path="/admin/adminUser/create" role="admin" component={CreateAdminUser} />
        <ProtectedRoute exact path="/admin/adminUser/edit/:id" role="admin" component={EditAdminUser} />
        {/* user */}
        <ProtectedRoute exact path="/admin/user" role="admin" component={User} />
        {/* pattern */}
        <ProtectedRoute exact path="/admin/pattern" role="admin" component={Pattern} />
        {/* Bet AMount */}
        <ProtectedRoute exact path="/admin/bet" role="admin" component={BetAmount} />
        {/* provider */}
        <ProtectedRoute exact path="/admin/provider" role="admin" component={Provider} />
        <ProtectedRoute exact path="/admin/provider/create" role="admin" component={CreateProvider} />
        <ProtectedRoute exact path="/admin/provider/edit/:id" role="admin" component={EditProvider} />
        {/* game */}
        <ProtectedRoute exact path="/admin/game" role="admin" component={Game} />
        <ProtectedRoute exact path="/admin/game/create" role="admin" component={CreateGame} />
        <ProtectedRoute exact path="/admin/game/edit/:id" role="admin" component={EditGame} />
        {/* setting */}
        <ProtectedRoute exact path="/admin/setting" role="admin" component={Setting} />
        <ProtectedRoute exact path="/admin/changepassword" role="admin" component={ChangePassword} />

        {/* user side */}
        <UserRoute exact path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default AppRouter;