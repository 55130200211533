import { fakerID_ID } from "@faker-js/faker";

const createRandomUser = () => {
  return fakerID_ID.internet.userName();
}

export const getActivity = () => {
  return fakerID_ID.helpers.multiple(createRandomUser, {
    count: 1000,
  })
}