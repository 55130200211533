import React from "react";
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { LoadingButton } from '@mui/lab';
import { IconButton, Stack, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import plusCircleOutline from '@iconify/icons-eva/plus-circle-outline';
import closeCircleOutline from '@iconify/icons-eva/close-circle-outline';
import styled from "styled-components";

import { getBackendPath } from "utils/common";

import BgImageSetting from "./BgImageSetting";
import GeneralSetting from "./GeneralSetting";

const StyledBox = styled.div`
  margin-top: 15px;

  h6 {
    margin-left: 15px;
  }

  hr {
    margin: 25px 0px;
    height: 1px;
    background-color: #ddd;
    border: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  font-size: 16px;
  svg {
    margin-right: 6px;
  }

  :hover {
    background-color: inherit !important;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
`;

const SettingForm = ({
  initialValues,
  onSubmit
}) => {
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values)
  });

  const { values, handleSubmit, getFieldProps, setFieldValue } = formik;

  const addRandomUser = (arrayHelpers) => {
    arrayHelpers.unshift("");
  }

  const removeRandomUser = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <StyledBox>
          <GeneralSetting getFieldProps={getFieldProps} />
          <BgImageSetting 
            enableAudio={values.enableAudio}
            audioLink={values.audio}
            bgImageBase64={values.backgroundImage ? getBackendPath(values.backgroundImage) : ""}
            setFieldValue={setFieldValue} 
          />
          <hr />
        </StyledBox> 
        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{m: 2}}>        
          <FieldArray 
            name="randomUsers"
            render={arrayHelpers => (
              <div style={{width: "100%"}}>
                <StyledIconButton onClick={() => addRandomUser(arrayHelpers, values?.randomUsers?.length)}>
                  <Icon icon={plusCircleOutline} width={20} height={20} />
                  Add Random UserName
                </StyledIconButton>

                <StyledGrid>
                  {
                    values?.randomUsers?.map((_, index) => (
                      <div key={index} className="mt-2">
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className="mb-2">
                          <TextField
                            fullWidth
                            label="Name"
                            {...getFieldProps(`randomUsers.${index}`)}
                          />
                          <StyledIconButton className="icon-remove" style={{marginLeft: "8px"}} onClick={() => removeRandomUser(arrayHelpers, index)}>
                            <Icon icon={closeCircleOutline} width={20} height={20} />
                          </StyledIconButton>
                        </Stack>
                      </div>
                    ))
                  }
                </StyledGrid>
              </div>
            )}
          />
        </Stack>  */}
        <LoadingButton
          sx={{m: 2, mb: 50}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

SettingForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

SettingForm.defaultProps = {
  initialValues: {
    backgroundImage: "",
    randomUsers: []
  },
  onSubmit: () => {}
};

export default SettingForm;
