import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { styled } from '@mui/material/styles';

import { getSetting } from "services/homepage.service";
import { getBackendPath } from "utils/common";

import { StyledBG } from "./styled";

const RootStyle = styled('div')({
  display: 'flex',
  overflow: 'hidden'
});

const MainStyle = styled('div')({
  flexGrow: 1,
  minHeight: '100vh',
});

export default function UserLayout({children}) {
  const { isSuccess: isSuccessSetting, data: resSetting } = useQuery(["setting"], getSetting);

  const [setting, setSetting] = useState(null);

  useEffect(() => {
    if (isSuccessSetting && resSetting) {
      setSetting(resSetting);
    }
  }, [isSuccessSetting, resSetting]);

  return (
    <RootStyle>
      {
        setting && (
          <React.Fragment>
            <MainStyle style={{ backgroundImage: `url("${getBackendPath(setting.bgImageBase64)}")`, backgroundSize: "cover" }}>
              <StyledBG>
                {/* {
                  (setting.bgImageBase64 && setting.bgImageBase64 !== "") && (
                    <img className="bgimage" src={getBackendPath(setting.bgImageBase64)} alt="bgimage" />
                  )
                } */}
                {children}
              </StyledBG>
            </MainStyle>
          </React.Fragment>
        )
      }
    </RootStyle>
  );
}
