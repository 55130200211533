import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 46%;
    padding: 10px;
    box-shadow: none;

    :hover {
      box-shadow: initial;
    }
  }

  .full-btn {
    width: 100%;
  }

  .register-btn {
    background-color: #FF9800;

    :hover {
      background-color: #FF9800;
    }
  }
`;

export const ContainerWrapper = styled.div`
  width: 650px;
  border: 2px solid #ddd;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;

  .title {
    padding: 15px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
    color: white;
  }

  .header {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    color: white;
    
    .logo-wrapper {
      display: flex;
      align-items: center;

      div:last-child {
        margin-left: 35px;
        font-size: 20px;
        font-weight: bold;
      }

      p {
        font-size: 16px;
      }

      img {
        min-width: 100px;
        width: 100px;
      }

      @media (max-width: 600px) {
        div {
          font-size: 14px !important;
        }

        img {
          min-width: 80px;
          width: 80px;
        }
      }
    }

    ol {
      margin-left: 20px;

      li {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }

  .content {
    border-bottom: 1px solid #ddd;
    color: white;

    .subtitle {
      padding: 10px 20px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
      display: flex;
      align-items: center;

      span {
        width: 15px;
        height: 15px;
        background: #8BC34A;
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
      }
    }

    .list-box {
      font-size: 14px;
      max-height: 265px;
      overflow: hidden;
      margin: 20px;

      > div {
        border: 1px solid #ddd;
        padding: 10px;
        background-color: rgb(167 142 142 / 32%);
      }

      span {
        font-weight: bold;
        color: #F44336;
      }
    }
  }

  .footer {
    color: white;
    padding: 20px 0px;
    text-align: center;
    font-size: 14px;

    > p {
      margin-bottom: 10px;

      :last-child {
        margin-top: 20px;
        margin-bottom: 0px;
      }
    }
  }

  .input-field {
    label {
      font-size: 14px
    }

    .MuiTextField-root {
      margin-top: 5px;
    }

    .MuiInputBase-root {
      color: white;
    }

    fieldset {
      border-color: white;
    }
  }

  .btn-wa {
    position: fixed;
    top: 7px;
    left: 50%;
    transform: translate(-50%);
    background-color: #128c7e;
  }

  .p-20 {
    padding: 20px;
  }

  p {
    font-size: 14px;
  }

  @media (max-width: 680px) {
    width: auto;
  }
`;

export const GameWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-height: 685px;
  overflow-y: scroll;
  margin-bottom: 25px;
  justify-content: center;

  .active-border {
    border: 3px solid #53B162;
  }

  > div {
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid;

    .bg-image {
      height: 120px;
      border-radius: 10px;
      background-size: cover;

      @media (max-width: 680px) {
        height: 100px;
        background-size: cover;
      }
    }

    .bg-icon {
      display: none;
      position: relative;
      top: 10px;
      left: 10px;
      width: 45px;
      height: 45px;
      align-items: center;
      background-color: #5151517d;
      border-radius: 50%;
      justify-content: center;
    }

    .hover-check {
      display: none;
      color: white;
    }

    .active-icon {
      display: flex;

      .hover-check {
        display: block;
      }
    }   

    p {
      text-align: center;
      margin: 5px 0px;
      font-weight: bold;
    }

    :hover {
      .hover-check {
        display: block;
      }
      .bg-icon {
        display: flex;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const FakeCodeWrapper = styled.div`
  .fake-header {
    background: #4b4b4b;
    background-image: -webkit-linear-gradient(top, #5b5b5b 0%, #474747 100%);
    background-image: -o-linear-gradient(top, #5b5b5b 0%, #474747 100%);
    background-image: linear-gradient(to bottom, #5b5b5b 0%, #474747 100%);
    color: #e2e2e2;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
  }

  .fake-content {
    padding: 15px;
    background: #292929;
    color: #e2e2e2;
    -webkit-box-shadow: inset 0 10px 15px 0 rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 10px 15px 0 rgba(0, 0, 0, 0.4);

    .normal-text {
      color: #4ebeff;
    }

    .done-text {
      color: green;
      margin-left: 5px;
    }

    .success-text {
      color: red;
    }
  }
`;

export const ResultWrapper = styled.div`
  display: grid;
  grid-template-columns: 190px 400px;
  grid-gap: 20px;

  @media (max-width: 680px) {
    grid-template-columns: 30% 70%;
    grid-gap: 10px;
  }

  img {
    border-radius: 10px;
  }

  .pattern-value {
    font-size: 16px;
    color: #ff9800;
  }

  .rtp-font {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    font-weight: bold;

    span {
      color: #FF9800;
    }
  }

  .title-game {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .pola-game {
    padding-top: 15px;

    p {
      display: flex;
      align-items: center;
      font-weight: 500;
      line-height: 26px;
    }

    .red-color {
      color: rgb(244, 67, 54);
    }

    .green-color {
      color: #8BC34A;
    }
  }

  .full-btn {
    width: 100%;
    margin-top: 5px;
  }

  .MuiFormControlLabel-root, .MuiSwitch-input {
    cursor: default;
  }

  .pattern-title {
    text-align: center;
    width: 100%;
    display: block !important;
    background-color: #FF9800;
    padding: 2px;
    margin-bottom: 6px;
    color: white;
  }
`;

export const MobileResultWrapper = styled.div`
  img {
    border-radius: 10px;
    margin: auto;
  }

  .pattern-value {
    font-size: 16px;
    color: #ff9800;
  }

  .rtp-font {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    font-weight: bold;

    span {
      color: #FF9800;
    }
  }

  .title-game {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .pola-game {
    padding-top: 15px;

    > div {
      width: fit-content;
      text-align: center;
    }

    p {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-weight: 500;
      width: auto;
      margin: auto;
    }

    .red-color {
      color: rgb(244, 67, 54);
    }

    .green-color {
      color: #8BC34A;
    }
  }

  .full-btn {
    width: 100%;
    margin-top: 10px;
  }

  .MuiFormControlLabel-root, .MuiSwitch-input {
    cursor: default;
  }

  .pattern-title {
    text-align: center;
    width: 100%;
    display: block !important;
    background-color: #FF9800;
    padding: 2px;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    color: white;
  }
`;

export const CountDownWrapper = styled.div`
  text-align: center;
  font-size: 46px;
  width: 300px;
  margin: 10px auto 45px !important;
  border: 1px solid;
  background-color: #ff980040;
  margin-bottom: 45px;
  margin-top: 25px;

`;