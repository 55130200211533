import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/page';
import Scrollbar from 'components/scrollBar';
import DatatTableAdmin from 'components/dataTableAdmin';
import ModalConfirmation from 'components/modalConfirmation';
import UserMoreMenu from 'components/userMoreMenu';
// services
import { getProviders, deleteProvider } from "services/provider.service";

export default function Provider() {
  const history = useHistory();
  const { isSuccess, data } = useQuery(["providers"], getProviders);

  const [providers, setProviders] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setProviders(data);
    }
  }, [isSuccess, data]);

  const columnProviders = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "Name",
        selector: row => row.name,
        sortable: true,
      },
      {
        name: "Min Value",
        selector: row => row.minValue,
        sortable: true,
      },
      {
        name: "Max Value",
        selector: row => row.maxValue,
        sortable: true,
      },
      {
        name: "Enable Check Close",
        selector: row => row.enableCheckClose ? "Yes" : "No",
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/admin/provider/edit/${row._id}`)}
            onDelete={() => {
              setDeletedId(row._id);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceProviders = useMemo(() => {
    return providers;
  }, [providers]);

  const handleDelete = useMutation(deleteId => {
    return deleteProvider({deleteId});
  });

  const onDelete = () => {
    handleDelete.mutate(deleteId, {
      onSuccess: () => {
        setProviders(providers.filter(x => x._id !== deleteId));
        setShow(false);
      }
    });
  }

  return (
    <Page title="Provider">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Provider
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/admin/provider/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New Provider
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <DatatTableAdmin
                columns={columnProviders}
                data={sourceProviders}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => onDelete()}
        />
      </Container>
    </Page>
  );
}
