import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

import Page from 'components/page';
import { createGame } from "services/game.service";
import { getProviders } from "services/provider.service";

import GameForm from "./GameForm";

const CreateGameForm = () => {
  const history = useHistory();

  const { isSuccess, data } = useQuery(["providers"], getProviders);

  const [providers, setProviders] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setProviders(data);
    }
  }, [isSuccess, data]);

  const submitGame = useMutation(values => {
    return createGame({values});
  });

  const onSubmitGame = (values) => {
    submitGame.mutate(values, {
      onSuccess: (res) => {
        if (res)
          history.goBack();
      }
    });
  }

  return (
    <Page title="Create Game">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Game
          </Typography>
        </Stack>

        <Card>
          <GameForm 
            initialValues={{
              providerId: providers.length > 0 ? providers[0]._id : ""
            }}
            providers={providers}
            onSubmit={onSubmitGame}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CreateGameForm;