import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import styled from "styled-components";

import { getProviders } from "services/provider.service";

const StyledContent = styled.div`
  width: 350px;
  padding-top: 20px;
`;

const ModalCreate = ({
  open,
  dataBetAmount,
  handleClose,
  handleOk,
  props
}) => {
  const type = dataBetAmount?._id ? "Update" : "Create";

  const { isSuccess, data } = useQuery(["providers"], getProviders);

  const [betAmount, setBetAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [providerId, setProviderId] = useState("");
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.length > 0 && type === "Create") {
        setProviderId(data[0]._id);
      }

      setProviders(data);
    }
  }, [isSuccess, data, type]);

  useEffect(() => {
    if (dataBetAmount !== null) {
      setBetAmount(dataBetAmount?.bet);
      setPercentage(dataBetAmount?.percentage);
      setProviderId(dataBetAmount?.providerId);
    }
  }, [dataBetAmount]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {type} BetAmount
      </DialogTitle>
      <DialogContent>
        <StyledContent>
          <Select
            fullWidth
            className="mb-2"
            label="Select Provider"
            value={providerId}
            onChange={e => setProviderId(e.target.value)}
          >
            {
              providers.map((provider, index) => (
                <MenuItem key={index} value={provider._id}>{provider.name}</MenuItem>
              ))
            }
          </Select>
          <TextField
            fullWidth
            className="mb-2"
            label="Bet Amount"
            type="number"
            value={betAmount}
            onChange={e => setBetAmount(e.target.value)}
          />
          <TextField
            fullWidth
            label="Percentage (%)"
            type="number"
            value={percentage}
            onChange={e => setPercentage(e.target.value)}
          />
        </StyledContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button 
          autoFocus
          onClick={() => handleOk({
            bet: betAmount,
            percentage: percentage,
            providerId: providerId,
            _id: dataBetAmount?._id
          })} 
        >
          {type}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCreate.propTypes = {
  open: PropTypes.bool,
  dataBetAmount: PropTypes.object,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
};

ModalCreate.defaultProps = {
  open: false,
  dataBetAmount: {
    bet: 0
  },
  handleClose: () => {},
  handleOk: () => {},
};

export default ModalCreate;
