import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { Container, Stack, Typography, Card } from '@mui/material';

import Page from 'components/page';
import { getSetting, saveSetting } from "services/setting.service";

import SettingForm from "./SettingForm";

const Setting = () => {
  const queryClient = new QueryClient();

  const { isSuccess, data } = useQuery(["setting"], getSetting);

  const [setting, setSetting] = useState(null);

  useEffect(() => {
    if (isSuccess && data) {
      setSetting(data);
    }
  }, [isSuccess, data]);


  const submitSetting = useMutation(values => {
    return saveSetting({values});
  });

  const onSubmitSetting = (values) => {
    const formValues = new FormData();

    formValues.append("siteName", values.siteName);
    formValues.append("loginLink", values.loginLink);
    formValues.append("daftarLink", values.daftarLink);
    formValues.append("whatsappLink", values.whatsappLink);
    formValues.append("minRTP", values.minRTP);
    formValues.append("maxRTP", values.maxRTP);
    formValues.append("randomUsers", JSON.stringify(values.randomUsers));
    if (values._id) {
      formValues.append("_id", values._id);
    }
    if (values.fileBgImage) {
      formValues.append("fileBgImage", values.fileBgImage);
    }

    submitSetting.mutate(formValues, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["setting"], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["setting"]);
      }
    });
  }

  return (
    <Page title="Setting">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Setting
          </Typography>
        </Stack>

        <Card>
          {
            setting && (
              <SettingForm 
                initialValues={setting}
                onSubmit={onSubmitSetting}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default Setting;