import adminSvg from "assets/icons/admin.svg";
import userSvg from "assets/icons/user.svg";
import gameSvg from "assets/icons/game.svg";
import providerSvg from "assets/icons/provider.svg";
import patternSvg from "assets/icons/pattern.svg";
import moneySvg from "assets/icons/money.svg";
import settingSvg from "assets/icons/setting.svg";

const adminSidebar = [
  {
    title: 'admin',
    path: '/admin/adminUser',
    icon: <img src={adminSvg} alt="adminSvg" />
  },
  {
    title: 'user',
    path: '/admin/user',
    icon: <img src={userSvg} alt="userSvg" />
  },
  {
    title: 'provider',
    path: '/admin/provider',
    icon: <img src={providerSvg} alt="providerSvg" />
  },
  {
    title: 'game',
    path: '/admin/game',
    icon: <img src={gameSvg} alt="gameSvg" />
  },
  {
    title: 'pattern',
    path: '/admin/pattern',
    icon: <img src={patternSvg} alt="patternSvg" />
  },
  {
    title: 'Bet Amount',
    path: '/admin/bet',
    icon: <img src={moneySvg} alt="moneySvg" />
  },
  {
    title: 'setting',
    path: '/admin/setting',
    icon: <img src={settingSvg} alt="settingSvg" />
  }
];

export default adminSidebar;
