import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getGames = async ({ queryKey }) => {
  try {
    const { providerId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/admin/game`, {
      params: {
        providerId: providerId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getGame = async ({ queryKey }) => {
  const { gameId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/game/${gameId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const createGame = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/game/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updateGame = async ({values, gameId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/game/${gameId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const deleteGame = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/game/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}