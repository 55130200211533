import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import useReactIpLocation from "react-ip-details";
import { 
  TextField,
  Button 
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-fill';

import LogoPNG from "assets/logo.svg";
import { 
  getBackendPath, 
  getRandomValuesFromArray, 
  getRandomValueByPercentage,
  getRandomNumber,
  generateRandomBooleans
} from "utils/common";
import { 
  getSetting, 
  getPatterns, 
  getBetAmounts, 
  getGames,
  getProviders,
  getUsers,
  addUser
} from "services/homepage.service";
import { alertError } from "services/alert.service";

import RecentActivity from "./RecentActivity";
import FakeCode from "./FakeCode";
import ResultCheat from "./ResultCheat";
import ModalDaftar from"./ModalDaftar";
import { ContainerWrapper, ButtonWrapper, GameWrapper } from "./styled";

export default function Homepage() {
  const location = useLocation();
  const [setting, setSetting] = useState(null);
  const [userName, setUserName] = useState("");
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [patterns, setPatterns] = useState([]);
  const [providers, setProviders] = useState([]);
  const [games, setGames] = useState([]);
  const [betAmounts, setBetAmounts] = useState([]);
  const [resultProviders, setResultProviders] = useState([]);
  const [isDraftInject, setIsDraftInject] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [isStartInject, setIsStartInject] = useState(false);
  const [finishedInject, setFinishedInject] = useState(false);
  const [isFailInject, setIsFailInject] = useState(false);
  const ipDetail = useReactIpLocation({ numberToConvert: 100 });

  const { isSuccess, data } = useQuery(["setting"], getSetting);
  const { isSuccess: isSuccessProviders, data: resProviders } = useQuery(["providers"], getProviders);
  const { isSuccess: isSuccessBetAmounts, data: resBetAmounts } = useQuery(
    ["betAmounts", { providerId: selectedProviders[0] }], 
    getBetAmounts,
    { enabled: (selectedProviders.length > 0) }
  );
  const { isSuccess: isSuccessPatterns, data: resPatterns } = useQuery(
    ["patterns", { providerId: selectedProviders[0] }], 
    getPatterns,
    { enabled: (selectedProviders.length > 0) }
  );
  const { isSuccess: isSuccessGames, data: resGames } = useQuery(
    ["games", { providerId: selectedProviders[0] }], 
    getGames,
    { enabled: (selectedProviders.length > 0) }
  );

  // useEffect(() => {
  //   if (localStorage.getItem("isStartInject")) {
  //     setUserName(localStorage.getItem("userName"));
  //     setIsStartInject(true);
  //     localStorage.removeItem("isStartInject");
  //     localStorage.removeItem("userName");
  //   }
  // }, []);

  // useEffect(() => {
  //   const watchInject = setInterval(() => {
  //     if (localStorage.getItem("finishedInject")) {
  //       setFinishedInject(true);
  //       localStorage.removeItem("finishedInject");
  //     }
  //   }, 500);

  //   return () => clearInterval(watchInject);
  // }, []);

  useEffect(() => {
    if (isSuccess && data) {
      setSetting(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessBetAmounts && resBetAmounts) {
      setBetAmounts(resBetAmounts.sort((a, b) => (b.percentage ? b.percentage : 0) - (a.percentage ? a.percentage : 0)));
    }
  }, [isSuccessBetAmounts, resBetAmounts]);

  useEffect(() => {
    if (isSuccessProviders && resProviders) {
      setProviders(resProviders);
    }
  }, [isSuccessProviders, resProviders]);

  useEffect(() => {
    if (isSuccessPatterns && resPatterns) {
      setPatterns(resPatterns);
    }
  }, [isSuccessPatterns, resPatterns]);

  useEffect(() => {
    if (isSuccessGames && resGames) {
      setGames(resGames);
    }
  }, [isSuccessGames, resGames]);

  const handleChooseProvider = (value) => {
    // if (selectedProviders.includes(value)) {
    //   setSelectedProviders(selectedProviders.filter(x => x !== value));
    // } else {
    //   setSelectedProviders([...selectedProviders, value]);
    // }

    setSelectedProviders([value]);
  }

  const handleRegisterOnClick = () => {
    window.open(setting.daftarLink ? setting.daftarLink : "", "_blank");
    // setUserName("");
    // setIsRegister(true);
    // setIsFailInject(false);
    // setIsDraftInject(false);
  }

  const handleWhatsappOnClick = () => {
    window.open(setting.whatsappLink ? setting.whatsappLink : "", "_blank");
  }

  const handleRegister = () => {
    addUser({userName})
    .then((res) => {
      setIsFailInject(false);
      setIsRegister(false);
    })
  }

  const handleInject = () => {
    if (selectedProviders.length > 0) {
      getUsers(userName)
      .then((res) => {
        if (res?.length > 0) {
          const providerData = providers.find(x => x._id === selectedProviders[0]);
          const randomGames = games.length > 3 ? getRandomValuesFromArray(games, 3) : games;
          const randomGameValues = randomGames.map((game) => {
            const randomPatterns = getRandomPattern(providerData);
            const randomBetAmount = betAmounts.length > 0 ? getRandomValueByPercentage(betAmounts) : 1;
            console.log(randomBetAmount)

            let polaValue = 0;
            randomPatterns.map((pattern) => {
              const numberValue = pattern.text.match(/\d+(\.\d+)?/g);
              polaValue += parseFloat(numberValue);
            })

            return {
              ...game,
              randomAmount: randomBetAmount.toLocaleString(),
              totalAmount: (randomBetAmount * polaValue).toLocaleString(),
              rtp: getRandomNumber(setting.minRTP, setting.maxRTP),
              patterns: randomPatterns,
              enableCheckClose: providerData.enableCheckClose
            }
          });
      
          setResultProviders(randomGameValues);
          setIsDraftInject(false);
          setIsRegister(false);
          setIsFailInject(false);
          setIsStartInject(true);
          setUserName(userName);
        } else {
          setIsFailInject(true);
          // alertError("Silahkan Daftar Terlebih Dahulu, Jika sudah daftar dan masih tidak dapat mengaktifkan cheat Silahkan hubungi WhatsApp atau Live Chat Customer Service");
        }
      })
    } else {
      alertError("Silahkan pilih game terlebih dahulu");
    }
  }

  const getRandomPattern = (providerData) => {
    const randomPatterns = getRandomValuesFromArray(patterns, 3);

    return randomPatterns.map((pattern) => (
      {
        ...pattern,
        randomBooleans: generateRandomBooleans(3),
        text: pattern.text.replace("{NO}", getRandomNumber(providerData.minValue, providerData.maxValue))
      }
    ))
  }

  return (
    setting ? (
      <ContainerWrapper>
        <Button
          className="ml-1 btn-wa"
          variant="contained"
          onClick={() => handleWhatsappOnClick()}
        >
          Chat WA Untuk Bantuan
        </Button>
        <div className="title">
          <span>ROBOT AUTO MAXWIN</span>
        </div>
        <div className="header">
          <div className="logo-wrapper">
            <div>
              <img src={LogoPNG} alt="logoImg" />
            </div>
            {
              userName === "" ? (
                <div>
                  INJECT ROBOT SUPER CERDAS KE AKUN ANDA SEKARANG, KEMENANGAN DI TANGAN ANDA!!
                </div>
              ) : (
                <div>
                  <span>{userName} - {ipDetail?.ipResponse?.IPv4}</span>
                  <br />
                  {ipDetail?.ipResponse?.country_name}
                  {
                    finishedInject && (
                      <React.Fragment>
                        <br />
                        <p style={{marginTop: "15px"}}>
                          ROBOT SUDAH BERHASIL DI INJECT!
                        </p>
                        <p>
                          KLIK LOGIN UNTUK LANGSUNG BERMAIN 
                        </p>
                        <p>
                          DAN PERHATIKAN POLA UNTUK MENINGKATKAN PERSENTASE KEMENANGAN
                        </p>
                      </React.Fragment>                      
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
        <div className="header">
          <div>  
            {
              finishedInject === true ? (
                <React.Fragment>
                  <ResultCheat setting={setting} values={resultProviders} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {
                    isStartInject === false ? (
                      <React.Fragment>
                        {
                          (!isDraftInject && !isRegister) && (
                            <React.Fragment>
                              <ol>
                                <li>Daftar terlebih dahulu!</li>
                                <li>Setelah Daftar Generate ID Anda di sini!</li>
                                <li>Pastikan setelah mendaftar anda <b>kembali ke halaman website ini</b> untuk melakukan inject cheat</li>
                              </ol>
                              <ButtonWrapper>
                                <Button
                                  className="register-btn mr-1"
                                  variant="contained"
                                  onClick={() => handleRegisterOnClick()}
                                >
                                  DAFTAR
                                </Button>
                                <Button
                                  className="ml-1"
                                  variant="contained"
                                  onClick={() => {
                                    setUserName("");
                                    setIsDraftInject(true);
                                    setIsFailInject(false);
                                  }}
                                >
                                  INJECT!
                                </Button>
                              </ButtonWrapper>
                            </React.Fragment>
                          )
                        }
      
                        {
                          isRegister === true && (
                            <React.Fragment>
                              <div className="input-field mb-2">
                                <label>Daftar username anda disini</label>
                                <TextField
                                  fullWidth
                                  label=""
                                  onChange={(e) => setUserName(e.target.value)}
                                />
                              </div>
            
                              <ButtonWrapper>
                                <Button
                                  className="full-btn register-btn"
                                  variant="contained"
                                  onClick={() => handleRegister()}
                                >
                                  DAFTAR
                                </Button>
                              </ButtonWrapper>
                            </React.Fragment>
                          )
                        }
      
                        {
                          isDraftInject === true && (
                            <React.Fragment>
                              <p className="fw-bold mb-1">Silahkan pilih provider yang ingin dibobol untuk pemakain cheat</p>
            
                              <GameWrapper>
                                {
                                  providers.map((provider, index) => (
                                    <div 
                                      key={index} 
                                      className={selectedProviders.includes(provider._id) ? "active-border" : "bg-icon"} 
                                      onClick={() => handleChooseProvider(provider._id)}
                                    >
                                      <div className="bg-image" style={{ backgroundImage: `url("${getBackendPath(provider.image)}")` }}>
                                        <div className={selectedProviders.includes(provider._id) ? "bg-icon active-icon" : "bg-icon"}>
                                          {
                                            selectedProviders.includes(provider._id) && (
                                              <Icon 
                                                className={"hover-check"} 
                                                icon={checkmarkCircleFill} 
                                                width={40} 
                                                height={40} 
                                              />
                                            )
                                          }
                                        </div>
                                      </div>
                                      <p>{provider.name}</p>
                                    </div>
                                  ))
                                }
                              </GameWrapper>
            
                              <div className="input-field mb-2">
                                <label>Masukkan username anda disini</label>
                                <TextField
                                  fullWidth
                                  label=""
                                  onChange={(e) => setUserName(e.target.value)}
                                />
                              </div>
            
                              <ButtonWrapper>
                                <Button
                                  className="full-btn"
                                  variant="contained"
                                  onClick={() => handleInject()}
                                >
                                  KLIK UNTUK AKTIFKAN CHEAT KE ID ANDA!
                                </Button>
                              </ButtonWrapper>
                            </React.Fragment>
                          )
                        }      
                      </React.Fragment>
                    ) : (
                      <FakeCode setFinishedInject={setFinishedInject} setIsStartInject={setIsStartInject} />
                    )
                  }
                </React.Fragment>
              )
            }

          </div>
        </div>
        <div className="content" style={{display: isStartInject === true ? "none" : "block"}}>
          <RecentActivity />
        </div>
        <div className="footer">
          <p>100% Tidak Terdeteksi</p>
          <p>Cheat Berlaku untuk berbagai jenis Permainan</p>
          <p>Cheat Sangat efektif Disitus Tersebut.</p>

          <hr style={{marginTop: "20px"}} />
          <p><b>Copyright ©{setting?.siteName}, all rights reserved 18+.</b></p>
        </div>

        <ModalDaftar 
          open={isFailInject}
          handleOk={() => {
            setIsFailInject(false);
            handleRegisterOnClick();
          }}
          handleClose={() => setIsFailInject(false)}
        />
      </ContainerWrapper>
    ) : (
      ""
    )
  );
}
