import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getPatterns = async ({ queryKey }) => {
  try {
    const { providerId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/admin/pattern`, {
      params: {
        providerId: providerId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getPattern = async ({ queryKey }) => {
  const { patternId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/pattern/${patternId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const createPattern = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/pattern/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updatePattern = async ({values, patternId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/pattern/${patternId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const deletePattern = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/pattern/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}