import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  Select,
  MenuItem
} from '@mui/material';
// components
import Page from 'components/page';
import Scrollbar from 'components/scrollBar';
import DatatTableAdmin from 'components/dataTableAdmin';
import ModalConfirmation from 'components/modalConfirmation';
import UserMoreMenu from 'components/userMoreMenu';
// services
import { getGames, deleteGame } from "services/game.service";
import { getProviders } from "services/provider.service";

export default function Game() {
  const history = useHistory();

  const [providers, setProviders] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);

  const { isSuccess: isSuccessProvider, data: resProvider } = useQuery(["providers"], getProviders);
  const { isSuccess, data } = useQuery(
    ["games", { providerId: selectedProvider }], 
    getGames,
    { enabled: selectedProvider !== "" }
  );

  useEffect(() => {
    if (isSuccess) {
      setGames(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessProvider) {
      setProviders(resProvider);

      if (resProvider?.length > 0) {
        setSelectedProvider(resProvider[0]._id);
      }
    }
  }, [isSuccessProvider, resProvider]);

  const columnGames = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "Name",
        selector: row => row.name,
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/admin/game/edit/${row._id}`)}
            onDelete={() => {
              setDeletedId(row._id);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceGames = useMemo(() => {
    return games;
  }, [games]);

  const handleDelete = useMutation(deleteId => {
    return deleteGame({deleteId});
  });

  const onDelete = () => {
    handleDelete.mutate(deleteId, {
      onSuccess: () => {
        setGames(games.filter(x => x._id !== deleteId));
        setShow(false);
      }
    });
  }

  return (
    <Page title="Game">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Game
          </Typography>
          <Select
            value={selectedProvider}
            onChange={e => setSelectedProvider(e.target.value)}
            style={{width: "200px"}}
          >
            {
              providers.map((provider, index) => (
                <MenuItem key={index} value={provider._id}>{provider.name}</MenuItem>
              ))
            }
          </Select>
          <Button
            variant="contained"
            component={RouterLink}
            to="/admin/game/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New Game
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <DatatTableAdmin
                columns={columnGames}
                data={sourceGames}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => onDelete()}
        />
      </Container>
    </Page>
  );
}
